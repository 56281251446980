import { VXETable } from 'vxe-table';
import request from '@/found/utils/request';
import _ from 'xe-utils';

export default class Print {
  constructor(paramData = {}, detailParams = {}) {
    const {
      id,
      logId,
      type,
      rowId,
      businessNo,
      processKey,
    } = paramData;
    this.title = '';
    this.url = '';
    this.id = id;
    this.detailParams = detailParams;
    this.type = type;
    this.logId = logId;
    this.rowId = rowId;
    this.businessNo = businessNo;
    this.processKey = processKey;
  }

  // 打印
  print() {
    console.log(this.type);
    const noParms = {
      id: this.rowId,
      businessId: this.id,
      businessNo: this.businessNo,
      processKey: this.processKey,
    };
    console.log(this.rowId, this.businessNo, this.processKey);
    request.post('/activiti/activitiController/countBusinessProcess', noParms).then((res) => {
      const resData = res.result;
      const html = `
       <div style="display:flex;justify-content: space-between;margin-bottom: 10px">
            <p><span>打印岗位:</span><span>${resData.optPosName || ''} (${resData.optPosCode || ''})</span></p>
            <p><span>打印人:</span><span>${resData.userName || ''} (${resData.userCode || ''})</span></p>
            <p><span>打印时间:</span><span>${resData.optDate || ''}</span></p>
            <p><span>打印次数:</span><span>${resData.n || ''}</span></p>
        </div>
      `;
      console.log(res);
      if (res.success) {
        switch (this.type) {
          case 'salesorder_examine': // 促销品订单打印
            this.url = '/dms/dms/order/query';
            this.title = '促销品订单打印';
            this.PromotionalItemsOrderPrinting(html);
            break;
          case 'channel_cost': // 渠道费用申请单
            this.url = '/dms/dms/channelfeeapply/queryApply';
            this.title = '渠道费用申请单';
            this.printChannelCost(html);
            break;
          case 'marketActivity': // 市场活动费用申请单
            this.url = '/tpm/tpmActController/query';
            this.title = '市场活动费用申请单';
            this.printMarketActivity(html);
            break;
          case 'extraCost': // 额外费用预算申请单
            this.title = '额外费用预算申请单';
            this.url = '/tpm/tpmFeeBudgetController/query';
            this.printExtraCost(html);
            break;
          case 'preInvest': // 市场预投入费用预算申请单
            this.url = '/tpm/tpmFeeBudgetController/query';
            this.title = '市场预投入费用预算申请单';
            this.printPreInvest(html);
            break;
          case 'verifyForm':
            this.url = '/tpm/tpmAuditController/query'; // 核销单
            this.title = '核销单';
            this.printVerifyForm(html);
            break;
          case 'marketPromote': // 市场推广活动费用申请单
            this.url = '/tpm/tpmActController/query';
            this.title = '市场推广活动费用申请单';
            this.printMarketActivity(html);
            break;
          case 'actClose': // 活动关闭
            this.url = '/tpm/tpmActReconsider/query';
            this.title = '活动关闭申请单';
            this.printCloseReconsider('关闭', html);
            break;
          case 'actReconsideration': // 活动复议
            this.url = '/tpm/tpmActReconsider/query';
            this.title = '活动复议申请单';
            this.printCloseReconsider('复议', html);
            break;
          case 'functionalDept': // 职能部门领用活动费用申请单
            this.title = '职能部门领用活动费用申请单';
            this.url = '/tpm/tpmActController/query';
            this.printDeptRequisition(html);
            break;
          case 'adjust_bill': // 客户调账
            this.title = '客户调账';
            this.url = '/dms/customeradjustaccount/getDetail';
            this.printMarketAdjustBill(html);
            break;
          case 'return_product': // 退换货申请
            this.title = '退换货申请';
            this.url = '/dms/changeProduct/getDetailByIds';
            this.printMarketReturnProduct(html);
            break;
          case 'newpro': // 新产品开发
            this.title = '新产品开发申请';
            this.url = '/mdm/newproduct/getDetail';
            this.developmentProduct(html);
            break;
          case 'pro_mod': // 产品微调
            this.title = '产品微调';
            this.url = '/mdm/productchange/getDetail';
            this.adjustProduct(html);
            break;
          case 'cust_stop': // 客户终止
            this.title = '客户终止业务审批单';
            this.url = '/mdm/MdmCustomerTerminationController/queryDetail';
            this.customerTerminate(html);
            break;
          case 'cost_summary': // 经销商费用上账
            this.title = '经销商费用上账';
            this.url = '/cps-mobile/h5/cost/dealer/findById';
            this.costSummary(html);
            break;
          case 'award_application': // 年度模糊奖励
            this.title = '年度模糊奖励';
            this.url = '/tpm/tpmaward/query';
            this.annualFuzzyAward(html);
            break;
          case 'jlsq': // 经销商奖励申请
            this.title = '经销商奖励申请打印表';
            this.url = '/dms/dmsawarddealerbudget/query';
            this.awardApplication(html);
            break;
          case 'dms_adjust_budget':// 调整预算
            this.title = '调整预算';
            this.url = '/tpm/dmsbudgetapprovedetail/queryInOutDetail';
            this.budgetAdjustment(html);
            break;
          case 'customer_information_flow':// 客户信息
            this.title = '西凤酒新开经销商立户审批评估报告';
            this.url = '/mdm/mdmCustomerMsgExtController/queryDetail';
            this.customerMsg(html);
            break;
          default:
            console.log('出错了');
        }
      }
    });
  }

  // 通用流程日志
  // <td colspan="1">${_.toDateString(item.createTime, 'yyyy-MM-dd HH:mm:ss') || ''}</td>
  async commonLog(detail) {
    const logData = await this.getLog();
    let tempHtml = '';
    for (const item of logData) {
      tempHtml += `
              <tr>
                <td colspan="2">${item.processName || ''}</td>
                <td colspan="1">${item.applierName || ''}</td>
                <td colspan="2">${detail.createOrgName || ''}</td>
                <td colspan="1">${item.currentAccounts || ''}</td>
                <td colspan="2">${item.positionName || ''}</td>
                <td colspan="1">${item.operationStr || ''}</td>
                <td colspan="1">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</td>
                <td colspan="2">${item.content || ''}</td>
              </tr>`;
    }
    return `<tr>
        <td colspan="2">流程名称</td>
        <td colspan="1">流程发起人</td>
        <td colspan="2">发起人部门</td>
        <td colspan="1">审批人</td>
        <td colspan="2">审批人职位</td>
        <td colspan="1">审批结果</td>
        <td colspan="1">审批时间</td>
        <td colspan="2">审批意见</td>
      </tr>
      ${tempHtml}
    `;
  }

  // 获取详情
  async getDetail(method = 'get') {
    if (!this.id) return;
    const { result } = await request[method](this.url, { id: this.id, ids: this.id, ...this.detailParams });
    return result;
  }

  // 获取详情
  async getDetailPost() {
    if (!this.id) return;
    const { result } = await request.post(this.url, { ids: this.id }, {
      headers: {
        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
    return result;
  }

  // 获取详情
  async getDetailGet() {
    if (!this.id) return;
    const { result } = await request.get(this.url, { id: this.id }, {
      headers: {
        ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
    return result;
  }

  // 获取数据字典
  async getDicValue(type) {
    const { result } = await request.post('/mdm/mdmdictdata/list', { dictTypeCode: type });
    return result;
  }

  // 返回数据字典的翻译名称
  backName(list, type) {
    for (const item of list) {
      if (item.dictCode === type) {
        return item.dictValue;
      }
    }
  }
  // 人民币转义
  /* eslint-disable */
  digitUppercase(n) {
    Math.formatFloat = function (f, digit) {
      var m = Math.pow(10, digit);
      return Math.round(f * m, 10) / m;
    }
    const fraction = ['角', '分'];
    const digit = [
      '零', '壹', '贰', '叁', '肆',
      '伍', '陆', '柒', '捌', '玖',
    ];
    const unit = [
      ['元', '万', '亿'],
      ['', '拾', '佰', '仟'],
    ];
    const IsNum = Number(n);
    if (!isNaN(IsNum)) {
      const head = n < 0 ? '欠' : '';
      n = Math.abs(n);
      let s = '';
      for (var i = 0; i < fraction.length; i++) {
        console.log(Math.formatFloat(n * 100, 2));
        s += (digit[Math.floor(Math.formatFloat(n * 100, 2) / 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
        console.log(s);
      }
      s = s || '整';
      n = Math.floor(n);
      for (var i = 0; i < unit[0].length && n > 0; i++) {
        let p = '';
        for (let j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
      }
      return head + s.replace(/(零.)*零元/, '元')
        .replace(/(零.)+/g, '零')
        .replace(/^整$/, '零元整');
    }

    return '';
  }
  //格式化金额(后面两位小数会四舍五入)
  moneyFormats(value) {
    let a = Number(value);  //转为数字格式

    let b = a.toLocaleString('zh', { style: 'currency', currency: 'CNY' });

    return b
  }
  // 获取流程日志
  async getLog() {
    if (!this.logId) return;
    const { result } = await request.post('/activiti/runtimeController/getOptLogs', { processInstanceId: this.logId });
    let tempArr = [];
    let tempNewArr = [];
    if (result && result.length > 0) {
      tempArr = result;
      tempArr = tempArr.map((a) => {
        let a1 = [];
        a1 = a.optRecordRspVOList.map((b) => ({
          ...b,
          createTime: this.formatTime(b.createTime, 'h:m'),
          logDate: a.logDate,
        }));
        return { ...a, optRecordRspVOList: a1 };
      });
      for (const item of tempArr) {
        tempNewArr = tempNewArr.concat(item.optRecordRspVOList);
      }
    }
    return tempNewArr;
  }

  /**
     * 格式化时间
     */
  // 转换时间戳
  formatTime(timestamp, format) {
    const formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    let returnArr = [];
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    returnArr.push(year, month, day, hour, minute, second);
    returnArr = returnArr.map((a) => {
      let n = a;
      n = n.toString();
      return n[1] ? n : `0${n}`;
    });
    let aaa = format;
    for (const i in returnArr) {
      if (i) {
        aaa = aaa.replace(formatArr[i], returnArr[i]);
      }
    }
    return aaa;
  }

  // 打印
  beginPrint(html) {
    VXETable.print({
      sheetName: this.title,
      style: ` table {
                width:  100%;
                margin-bottom: 10px;
                table-layout:fixed;
                word-break:break-all;
                min-height: 25px;
                line-height: 25px;
                border-collapse: collapse;
                padding:2px;
              }
              td {
                text-align: center;
                line-height: 22px;
              }
              th {
                font-weight: bold;
              }
              p{

                padding: 0;
                margin:0
              }
              @media   print   {
                body   {   font-size: 8pt; line-height: 1.2}
                }
              `,
      content: html,
    });
  }

  // 打印职能部门领用
  async printDeptRequisition(newHtml) {
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    let detailHtml = '';
    for (const item of detail.detailVos) {
      let productHtml = '';
      for (const product of (item.normalProductList || [])) {
        productHtml += `<tr>
            <td colspan="1">产品名称</td>
            <td colspan="1">${product.productName || ''}</td>
            <td colspan="1">产品编码</td>
            <td colspan="1">${product.productCode || ''}</td>
            <td colspan="1">规格</td>
            <td colspan="1">${product.ext2 || ''}</td>
            <td colspan="1">单价</td>
            <td colspan="1">${product.price || ''}</td>
            <td colspan="1">数量</td>
            <td colspan="1">${product.ext1 || ''}</td>
            <td colspan="1">金额</td>
            <td colspan="1">${product.ext4 || ''}</td>
          </tr>`;
      }

      detailHtml += `
          <tr>
            <td colspan="2">活动明细名称</td>
            <td colspan="1">活动明细编码</td>
            <td colspan="1">预算编码</td>
            <td colspan="1">预算科目</td>
            <td colspan="2">公司主体</td>
            <td colspan="1">活动细类</td>
            <td colspan="1">内部客户</td>
            <td colspan="2">受益组织</td>
            <td colspan="1">活动申请金额</td>
          </tr>
          <tr>
            <td colspan="2">${item.ext1 || ''}</td>
            <td colspan="1">${item.actDetailCode || ''}</td>
            <td colspan="1">${item.budgetSubjectsCode || ''}</td>
            <td colspan="1">${item.ext19 || ''}</td>
            <td colspan="2">${item.ext3 || ''}</td>
            <td colspan="1">${item.categoriesName || ''}</td>
            <td colspan="1">${item.ext7 || ''}</td>
            <td colspan="2">${item.ext5 || ''}</td>
            <td colspan="1">${item.applyAmount || ''}</td>
          </tr>
         ${productHtml}
      `;
    }

    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                  <tr>
                    <td colspan="1">活动主题</td>
                    <td colspan="1">${detail.actName || ''}</td>
                    <td colspan="1">活动编码</td>
                    <td colspan="1">${detail.actCode || ''}</td>
                    <td colspan="1">活动时间</td>
                    <td colspan="1">${detail.beginDate || ''}-${detail.endDate || ''}</td>
                    <td colspan="1">创建人</td>
                    <td colspan="1">${detail.createName || ''}</td>
                    <td colspan="1">创建组织</td>
                    <td colspan="1">${detail.createOrgName || ''}</td>
                    <td colspan="1">创建时间</td>
                    <td colspan="1">${detail.createDateAll || ''}</td>
                  </tr>
                  <tr>
                    <td colspan="1">活动描述</td>
                    <td colspan="11">${detail.actDesc || ''}</td>
                  </tr>
                  <tr>
                    <td colspan="12">活动明细信息</td>
                  </tr>
                  ${detailHtml}
                  ${logHtml}
              </table>
              ${newHtml}`;
    this.beginPrint(html);
  }

  // 打印活动关闭-复议
  async printCloseReconsider(name,newHtml) {
    const complianceList = await this.getDicValue('yesOrNo');
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    let detailHtml = '';
    for (const item of detail.detailVos) {
      const compliance = this.backName(complianceList, item.ext10);
      detailHtml += ` <tr>
          <td colspan="1">活动明细编码</td>
          <td colspan="1">预算编码</td>
          <td colspan="2">预算科目</td>
          <td colspan="2">公司主体</td>
          <td colspan="1">活动细类</td>
          <td colspan="2">受益经销商</td>
          <td colspan="1">受益组织</td>
          <td colspan="1">活动申请金额</td>
          <td colspan="1">是否合规</td>
          <tr>
            <td colspan="1">${item.actDetailCode || ''}</td>
            <td colspan="1">${item.ext61 || ''}</td>
            <td colspan="2">${item.ext19 || ''}</td>
            <td colspan="2">${item.ext3 || ''}</td>
            <td colspan="1">${item.fineName || ''}</td>
            <td colspan="2">${item.ext7 || ''}</td>
            <td colspan="1">${item.ext5 || ''}</td>
            <td colspan="1">${item.applyAmount || ''}</td>
            <td colspan="1">${compliance}</td>
          </tr>
          <tr>
            <td colspan="1">活动细则</td>
            <td colspan="11">${item.ext13 || ''}</td>
          </tr>
          <tr>
            <td colspan="2">活动落实情况说明</td>
            <td colspan="5" style="height:100px"></td>
            <td colspan="1">活动负责人</td>
            <td colspan="1"></td>
            <td colspan="2">部门/分公司负责人</td>
            <td colspan="1"></td>
          </tr>
          <tr>
            <td colspan="2">监督检查情况</td>
            <td colspan="5" style="height:100px"></td>
            <td colspan="1">检查时间</td>
            <td colspan="1"></td>
            <td colspan="2">监督检查人员</td>
            <td colspan="1"></td>
          </tr>
        </tr>`;
    }
    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                    <tr>
                      <td colspan="1">活动${name}编码</td>
                      <td colspan="1">${detail.actCode || ''}</td>
                      <td colspan="1">活动主题</td>
                      <td colspan="1">${detail.actName}</td>
                      <td colspan="1">活动编码</td>
                      <td colspan="1">${detail.beginDate || ''} - ${detail.endDate || ''}</td>
                      <td colspan="1">创建人</td>
                      <td colspan="1">${detail.createName || ''}</td>
                      <td colspan="1">创建组织</td>
                      <td colspan="1">${detail.createOrgName || ''}</td>
                      <td colspan="1">创建时间</td>
                      <td colspan="1">${detail.createDateAll || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="12" style="height:25px"></td>
                    </tr>
                    <tr>
                      <td colspan="12">${name}活动明细信息</td>
                    </tr>
                    ${detailHtml}
                    ${logHtml}
            </table>
             ${newHtml}`;
    this.beginPrint(html);
  }
  
  // 打印核销单
  async printVerifyForm(newHtml) {
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    let checkHtml = '';
    for (const item of detail.detailVos) {
      console.log(JSON.parse(item.budgetDetail),'budgetDetail');
      let budgetDetail=JSON.parse(item.budgetDetail)
      let budgetCodeList=[]
      let fiscalYearList=[]
      let budgetCodeStr=item.ext79
      let fiscalYearStr=item.ext80
      if (budgetDetail&&budgetDetail.length>0) {
        budgetDetail.forEach((v)=>{
          budgetCodeList.push(v.budgetCode)
          fiscalYearList.push(v.fiscalYear)
        })
        budgetCodeStr=budgetCodeList.join(',')
        fiscalYearStr=fiscalYearList.join(',')
      }
      console.log(budgetCodeStr,fiscalYearStr,'budgetCodeStr,fiscalYearStr');
      checkHtml += `<tr>
                    <td colspan="1">核销明细编码</td>
                    <td colspan="1">活动明细编码</td>
                    <td colspan="1">活动明细名称</td>
                    <td colspan="1">财年</td>
                    <td colspan="1">预算编码</td>
                    <td colspan="1">预算科目</td>
                    <td colspan="2">受益经销商</td>
                    <td colspan="2">受益组织</td>
                    <td colspan="1">本次核报金额</td>
                    <td colspan="1">督查结果</td>
                    <tr>
                      <td colspan="1">${item.auditDetailCode || ''}</td>
                      <td colspan="1">${item.actDetailCode || ''}</td>
                      <td colspan="1">${item.ext1 || ''}</td>
                      <td colspan="1">${fiscalYearStr || ''}</td>
                      <td colspan="1">${budgetCodeStr || ''}</td>
                      <td colspan="1">${item.budgetSubjectsName || ''}</td>
                      <td colspan="2">${item.ext7 || ''}</td>
                      <td colspan="2">${item.ext5 || ''}</td>
                      <td colspan="1">${item.auditApplyAmount || ''}</td>
                      <td colspan="1">${item.ext20 || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">备注</td>
                      <td colspan="11">${item.remarks || ''}</td>
                    </tr>
                  </tr>`;
    }
    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                    <tr>
                      <td colspan="12">
                        <div style="margin:4px 0;font-weight:600">${detail.detailVos[0].ext3 || ''}</div>
                        <div style="margin:4px 0;font-weight:600">费用报销单</div>
                      </td>

                    </tr>
                    <tr>
                      <td colspan="1">核销编码</td>
                      <td colspan="1">${detail.auditCode || ''}</td>
                      <td colspan="1">核销名称</td>
                      <td colspan="1">${detail.auditName || ''}</td>
                      <td colspan="1">创建人</td>
                      <td colspan="1">${detail.createName || ''} - ${detail.ext3 || ''}</td>
                      <td colspan="1">创建人组织</td>
                      <td colspan="1">${detail.createOrgName || ''}</td>
                      <td colspan="1">创建时间</td>
                      <td colspan="1">${detail.createDateAll || ''}</td>
                      <td colspan="1">活动编码</td>
                      <td colspan="1">${detail.tpmAuditActReqVos[0].actCode || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">核销内容</td>
                      <td colspan="11">${detail.ext4 || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="12">核销明细信息</td>
                    </tr>
                    ${checkHtml}
                    ${logHtml}
            </table>
            ${newHtml}`;
    this.beginPrint(html);
  }

  // 打印额外费用
  async printExtraCost(newHtml) {
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                    <tr>
                      <td colspan="1">额外费用主题</td>
                      <td colspan="1">${detail.ext4 || ''}</td>
                      <td colspan="1">预算编码</td>
                      <td colspan="1">${detail.feeBudgetCode || ''}</td>
                      <td colspan="1">额外费用时间</td>
                      <td colspan="1">${detail.ext2 || ''} - ${detail.ext3 || ''}</td>
                      <td colspan="1">创建人</td>
                      <td colspan="1">${detail.createName || ''}</td>
                      <td colspan="1">创建人组织</td>
                      <td colspan="1">${detail.createOrgName || ''}</td>
                      <td colspan="1">创建时间</td>
                      <td colspan="1">${detail.createDateAll || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">财年</td>
                      <td colspan="1">${detail.ext1 || ''}</td>
                      <td colspan="1">公司主体</td>
                      <td colspan="1">${detail.ext8 || ''}</td>
                      <td colspan="1">预算科目</td>
                      <td colspan="1">${detail.budgetSubjectsName || ''}</td>
                      <td colspan="1">经销商</td>
                      <td colspan="1">${detail.customerName || ''}</td>
                      <td colspan="1">组织</td>
                      <td colspan="1">${detail.orgName || ''}</td>
                      <td colspan="1">申请金额</td>
                      <td colspan="1">${detail.initAmount || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">额外费用方案</td>
                      <td colspan="11">${detail.ext5 || ''}</td>
                    </tr>
                    <tr>
                    <td colspan="1">备注</td>
                    <td colspan="11">${detail.remarks || ''}</td>
                  </tr>
                  ${logHtml}
            </table>
            ${newHtml}`;
    this.beginPrint(html);
  }

  // 打印预投入
  async printPreInvest(newHtml) {
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                    <tr>
                      <td colspan="1">预投入主题</td>
                      <td colspan="1">${detail.ext4 || ''}</td>
                      <td colspan="1">预算编码</td>
                      <td colspan="1">${detail.feeBudgetCode || ''}</td>
                      <td colspan="1">投入周期</td>
                      <td colspan="1">${detail.ext2 || ''} - ${detail.ext3 || ''}</td>
                      <td colspan="1">创建人</td>
                      <td colspan="1">${detail.createName || ''}</td>
                      <td colspan="1">创建人组织</td>
                      <td colspan="1">${detail.createOrgName || ''}</td>
                      <td colspan="1">创建时间</td>
                      <td colspan="1">${detail.createDateAll || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">财年</td>
                      <td colspan="1">${detail.budgetYearName || ''}</td>
                      <td colspan="1">公司主体</td>
                      <td colspan="1">${detail.ext8 || ''}</td>
                      <td colspan="1">预算科目</td>
                      <td colspan="1">${detail.budgetSubjectsName || ''}</td>
                      <td colspan="1">经销商</td>
                      <td colspan="1">${detail.customerName || ''}</td>
                      <td colspan="1">经销商类型</td>
                      <td colspan="1">${detail.ext11 || ''}</td>
                      <td colspan="1">目标任务</td>
                      <td colspan="1">${detail.ext22 || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">预投入比例（%）</td>
                      <td colspan="1">${detail.ext21 || ''}</td>
                      <td colspan="1">预投入金额</td>
                      <td colspan="1">${detail.initAmount || ''}</td>
                      <td colspan="1">备注</td>
                      <td colspan="7">${detail.remarks || ''}</td>
                    </tr>
                    ${logHtml}
            </table>
            ${newHtml}`;
    this.beginPrint(html);
  }

  // 促销品订单打印
  async PromotionalItemsOrderPrinting(newHtml) {
    if (!this.id) return;
    const { result } = await request.post(this.url, { id: this.id });
    const logData = await this.getLog();
    const detail = result;
    let detailHtml = '';
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr rowspan="1">
      <td colspan="2">${item.positionName}</td>
      <td colspan="8">
          <p style="text-align: left">${item.content || ''}</p>
          <div style="display:flex;flex-direction: column;align-items: flex-start;text-align: left">
              <p style="width: 120px">${item.userName}</p><p style="width: 120px">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</p>
          </div>
      </td>
      <td colspan=""></td>
  </tr>`;
    });
    detail.detailList.forEach((item, index) => {
      detailHtml += `
      <tr>
        <td colspan="2">${index + 1}</td>
        <td colspan="4">${item.productName || ''}</td>
        <td colspan="2">${item.productCode || ''}</td>
        <td colspan="1">${item.spec || ''}</td>
        <td colspan="1">${item.num || ''}</td>
        <td colspan="1">${item.productPrice || ''}</td>
        <td colspan="1">${item.productTotalPrice || ''}</td>
      </tr>`;
    });
    const html = `
    <table style="width: 100%;" border="1" cellspacing="0">
    <h2 style="text-align: center;margin: 10px 0">促销品需货申请</h2>
    <p style="margin-bottom: 10px;"><span>需货申请单号:</span><span>${detail.orderCode}</span></p>
    <tr>
      <td colspan="2">申请人</td>
      <td colspan="4">${detail.responseName || ''}</td>
      <td colspan="2">申请人分公司</td>
      <td colspan="4">${detail.responseBranchName || ''}</td>
    </tr>
    <tr>
      <td colspan="2">申请日期</td>
      <td colspan="4">${detail.createDate || ''}</td>
      <td colspan="2">申请人组织</td>
      <td colspan="4">${detail.responseOrgName || ''}</td>
    </tr>
    <tr>
      <td colspan="2">申请总金额</td>
      <td colspan="4">${detail.amount || ''}</td>
      <td colspan="2">活动金额</td>
      <td colspan="4">${detail.actCash || ''}</td>
    </tr>
    <tr>
      <td colspan="2">活动名称</td>
      <td colspan="4">${detail.relateActName || ''}</td>
      <td colspan="2">活动明细编码</td>
      <td colspan="4">${detail.relateActCode || ''}</td>
    </tr>
    <tr style="height:50px" >
      <td colspan="2">订单备注</td>
      <td colspan="10">${detail.remarks || ''}</td>
    </tr>
    <tr>
    <td colspan="2">序号</td>
    <td colspan="4">产品名称</td>
    <td colspan="2">产品编码</td>
    <td colspan="1">规格</td>
    <td colspan="1">申请数量</td>
    <td colspan="1">单价</td>
    <td colspan="1">金额</td>
    </tr>
    ${detailHtml}
    <tr>
    <td colspan="12">业务流程</td>
    </tr>
    ${logTr}
    </table>
    ${newHtml}
    `;
    this.beginPrint(html);
  }
  // 年度模糊奖励
  async annualFuzzyAward(newHtml) {
    if (!this.id) return;
    const { result } = await request.post(this.url, { id: this.id });
    const logData = await this.getLog();
    const detail = result;
    let data = null
    // request.post('/tpm/tpmFeeBudgetController/chooseControl', { feeBudgetCode: detail.detailRespVo.budgetCode ,ext2:'1'}).then((res) => {
    request.post('/tpm/tpmFeeBudgetController/chooseControlQuery', { feeBudgetCode: detail.detailRespVo.budgetCode,ext2:'1'}).then((res) => {
      if (res.success) {
        data = res.result.data[0];
      }
    });
    const complianceList = await this.getDicValue('yesOrNo');
    const isSupplyBill = this.backName(complianceList, detail.isDrawBill);
    const tpmProductTyp = await this.getDicValue('tpm_product_type1');
    const productSeriesName = this.backName(tpmProductTyp, detail.productSeriesCode);
    const payType = await this.getDicValue('award_pay_type');
    const payTypeName = this.backName(payType, detail.payType);
    const detailHtml = '';
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr rowspan="1" height="60px">
      <td colspan="2">${item.positionName || ''}</td>
      <td colspan="7">
        <span>
          <p>${item.content || ''}</p>
          <p style='text-align:right'>${item.userName || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p style='text-align:right'>${item.logDate || ''}${item.createTime || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        </span>
      </td>
      <td colspan="3"></td>
  </tr>`;
    });
    const html = `
    <table style="width: 100%;" border="1" cellspacing="0">
      <h2 style="text-align: center;margin: 10px 0">年度模糊奖励申请</h2>
      <tr height="60px">
        <td colspan="2">模糊奖励编号</td>
        <td colspan="4">${detail.awardCode || ''}</td>
        <td colspan="2">申请人</td>
        <td colspan="4">${detail.createName || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="2">客户</td>
        <td colspan="4">${detail.customerName || ''}</td>
        <td colspan="2">公司主体</td>
        <td colspan="4">${detail.companyName || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="2">是否提供发票</td>
        <td colspan="4">${isSupplyBill || ''}</td>
        <td colspan="2">产品系列</td>
        <td colspan="4">${productSeriesName || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="2">支付方式</td>
        <td colspan="4">${payTypeName || ''}</td>
        <td colspan="2">申请金额</td>
        <td colspan="4">${detail.totalAmount || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="12">预算信息</td>
      </tr>
      <tr height="60px">
        <td colspan="2">预算编码</td>
        <td colspan="4">${(data||{}).feeBudgetCode || ''}</td>
        <td colspan="2">财年</td>
        <td colspan="4">${(data||{}).ext1 || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="2">公司主体</td>
        <td colspan="4">${(data||{}).ext8 || ''}</td>
        <td colspan="2">预算科目</td>
        <td colspan="4">${(data||{}).budgetSubjectsName || ''}</td>
      </tr>
      <tr height="60px">
        <td colspan="12">审批信息</td>
      </tr>
      <tr height="60px">
        <td colspan="2">经办人</td>
        <td colspan="7">审批意见</td>
        <td colspan="3">签字确认</td>
      </tr>
      ${logTr}
      <tr height="60px">
        <td colspan="2">备注</td>
        <td colspan="10"></td>
      </tr>
    </table>
     ${newHtml}
    `;
    this.beginPrint(html);
  }
  // 调整预算
  async budgetAdjustment(newHtml){
    if (!this.id) return;
    const { result } = await request.post(this.url, { id: this.id });
    const logData = await this.getLog();
    const detail = result;
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr rowspan="1" height="60px">
        <td colspan="2">${item.positionName || ''}</td>
        <td colspan="7">
          <span>
            <p>${item.content || ''}</p>
            <p style='text-align:right'>${item.userName || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </span>
        </td>
        <td colspan="3">${item.logDate || ''}${item.createTime || ''}</td>
    </tr>`;
    });
    const html = `
      <table style="width: 100%;" border="1" cellspacing="0">
        <h2 style="text-align: center;margin: 10px 0">调整预算打印表</h2>
        <tr height="60px" >
          <td colspan="2">发起人</td>
          <td colspan="4">${detail.createName || ''}</td>
          <td colspan="2">调整金额</td>
          <td colspan="4">${detail.adjustMoney || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan = '12'>调出方</td>
        </tr>
        <tr height="60px">
          <td colspan="2">费用预算编码</td>
          <td colspan="4">${detail.budgetRespVo.budgetRespVo || ''}</td>
          <td colspan="2">年  份</td>
          <td colspan="4">${detail.budgetRespVo.budgetYearName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">预算科目</td>
          <td colspan="4">${detail.budgetRespVo.budgetSubjectsName || ''}</td>
          <td colspan="2">客  户</td>
          <td colspan="4">${detail.budgetRespVo.customerName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">公司主体</td>
          <td colspan="4">${detail.budgetRespVo.ext8 || ''}</td>
          <td colspan="2">组织</td>
          <td colspan="4">${detail.budgetRespVo.orgName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">产品层级</td>
          <td colspan="10">${detail.budgetRespVo.productLevelName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="12">调入方</td>
        </tr>
        <tr height="60px">
          <td colspan="2">费用预算编码</td>
          <td colspan="4">${detail.adjustBudgetRespVo.budgetRespVo || ''}</td>
          <td colspan="2">年  份</td>
          <td colspan="4">${detail.adjustBudgetRespVo.budgetYearName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">预算科目</td>
          <td colspan="4">${detail.adjustBudgetRespVo.budgetSubjectsName || ''}</td>
          <td colspan="2">客  户</td>
          <td colspan="4">${detail.adjustBudgetRespVo.customerName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">公司主体</td>
          <td colspan="4">${detail.adjustBudgetRespVo.ext8 || ''}</td>
          <td colspan="2">组织</td>
          <td colspan="4">${detail.adjustBudgetRespVo.orgName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">产品层级</td>
          <td colspan="10">${detail.adjustBudgetRespVo.productLevelName || ''}</td>
        </tr>
        <tr height="60px">
        <td colspan="12">审批信息</td>
        </tr>
        <tr height="60px">
        <td colspan="2">经办人</td>
        <td colspan="10">审批意见</td>
        </tr>
        ${logTr}
      </table>
       ${newHtml}
      `;
    this.beginPrint(html);
  }
  // 经销商奖励申请
  async awardApplication(newHtml) {
    if (!this.id) return;
    const { result } = await request.post(this.url, { id: this.id });
    const logData = await this.getLog();
    const detail = result;
    const awardType = await this.getDicValue('award_input_type');
    const awardTypeName = this.backName(awardType, detail.ext1);
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr rowspan="1" height="60px">
        <td colspan="2">${item.positionName || ''}</td>
        <td colspan="7">
          <span>
            <p>${item.content || ''}</p>
            <p style='text-align:right'>${item.userName || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </span>
        </td>
        <td colspan="3">${item.logDate || ''}${item.createTime || ''}</td>
    </tr>`;
    });
    const html = `
      <table style="width: 100%;" border="1" cellspacing="0">
        <h2 style="text-align: center;margin: 10px 0">经销商奖励申请申请打印表</h2>
        <tr height="60px">
          <td colspan="2">预算申请编码</td>
          <td colspan="4">${detail.dealerBudgetCode || ''}</td>
          <td colspan="2">受益经销商</td>
          <td colspan="4">${detail.dealerName || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">申请金额</td>
          <td colspan="4">${detail.applyAmount || ''}</td>
          <td colspan="2">年  度</td>
          <td colspan="4">${detail.year || ''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">公司主体</td>
          <td colspan="4">${detail.companyName || ''}</td>
          <td colspan="2">投入类型</td>
          <td colspan="4">${awardTypeName||''}</td>
        </tr>
        <tr height="60px">
          <td colspan="2">申请人</td>
          <td colspan="4">${detail.createName || ''}</td>
          <td colspan="6"></td>
        </tr>
        <tr height="60px">
          <td colspan="12">审批信息</td>
        </tr>
        <tr height="60px">
          <td colspan="2">经办人</td>
          <td colspan="7">审批意见</td>
          <td colspan="3">审批时间</td>
        </tr>
        ${logTr}
      </table>
       ${newHtml}
      `;
    this.beginPrint(html);
  }
  // 渠道费用申请单
  async printChannelCost(newHtml) {
    if (!this.id) return;
    const { result } = await request.post(this.url, { id: this.id });
    const logData = await this.getLog();
    const detail = result;
    const detailHtml = '';
    let isSupplyBill = '';
    if (detail.isSupplyBill === 'N') {
      isSupplyBill = '否';
    } else if (detail.isSupplyBill === 'Y') {
      isSupplyBill = '是';
    }
    let logTr = '';
    // const logDatarr = [];
    // logData.map((v) => {
    //   if (v.taskName) {
    //     logDatarr.push(v);
    //   }
    //   return v;
    // });
    logData.forEach((item) => {
      logTr += `<tr rowspan="1" height="60px">
      <td colspan="2">${item.positionName || ''}</td>
      <td colspan="10">
        <span>
          <p>${item.content || ''}</p>
          <p style='text-align:right'>${item.userName || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p style='text-align:right'>${item.logDate || ''}${item.createTime || ''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        </span>
      </td>
  </tr>`;
    });
    const html = `
    <table style="width: 100%;" border="1" cellspacing="0">
    <h2 style="text-align: center;margin: 10px 0">渠道费用申请单</h2>
    <tr height="60px">
      <td colspan="2">渠道费用编号</td>
      <td colspan="4">${detail.channelApplyCode || ''}</td>
      <td colspan="2">负责人</td>
      <td colspan="4">${detail.createName || ''}</td>
    </tr>
    <tr height="60px">
      <td colspan="2">经销商</td>
      <td colspan="4">${detail.custName || ''}</td>
      <td colspan="2">申请金额</td>
      <td colspan="4">${detail.applyCash || ''}</td>
    </tr>
    <tr height="60px">
      <td colspan="2">是否提供发票</td>
      <td colspan="4">${isSupplyBill || ''}</td>
      <td colspan="6"></td>
    </tr>
    <tr height="60px">
    <td colspan="12">审批信息</td>
    </tr>
    <tr height="60px">
    <td colspan="2">经办人</td>
    <td colspan="10">审批意见</td>
    </tr>
    ${logTr}
    <tr height="60px">
    <td colspan="2">备注</td>
    <td colspan="10"></td>
    </tr>
    </table>
     ${newHtml}
    `;
    this.beginPrint(html);
  }

  // 市场活动
  async printMarketActivity(newHtml) {
    const payTypeList = await this.getDicValue('pay_type_d');
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    let detailHtml = '';
    for (const item of detail.detailVos) {
      const typeName = this.backName(payTypeList, item.payType);
      console.log(JSON.parse(item.budgetDetail),'budgetDetail');
      let budgetDetail=JSON.parse(item.budgetDetail)
      let budgetCodeList=[]
      let budgetCodeStr=item.ext61
      if (budgetDetail&&budgetDetail.length>0) {
        budgetDetail.forEach((v)=>{
          budgetCodeList.push(v.budgetCode)
        })
        budgetCodeStr=budgetCodeList.join(',')
      }
      console.log(budgetCodeStr,'budgetCodeStr');
      detailHtml += ` <tr>
          <td colspan="1">活动明细编码</td>
          <td colspan="1">预算编码</td>
          <td colspan="2">预算科目</td>
          <td colspan="2">公司主体</td>
          <td colspan="1">活动细类</td>
          <td colspan="2">受益经销商</td>
          <td colspan="1">受益组织</td>
          <td colspan="1">活动申请金额</td>
          <td colspan="1">支付方式</td>
          <tr>
            <td colspan="1">${item.actDetailCode || ''}</td>
            <td colspan="1">${budgetCodeStr || ''}</td>
            <td colspan="2">${item.ext19 || ''}</td>
            <td colspan="2">${item.ext3 || ''}</td>
            <td colspan="1">${item.fineName || ''}</td>
            <td colspan="2">${item.ext7 || ''}</td>
            <td colspan="1">${item.ext5 || ''}</td>
            <td colspan="1">${item.applyAmount || ''}</td>
            <td colspan="1">${typeName}</td>
          </tr>
          <tr>
            <td colspan="1">活动细则</td>
            <td colspan="11">${item.ext13 || ''}</td>
          </tr>
          <tr>
            <td colspan="2">活动落实情况说明</td>
            <td colspan="5" style="height:100px"></td>
            <td colspan="2">活动负责人</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td colspan="2">监察审计部综合意见</td>
            <td colspan="10" style="height:100px"></td>
          </tr>
        </tr>`;
    }
    const html = `<table style="width: 100%;" border="1" cellspacing="0">
                    <tr>
                      <td colspan="1">活动主题</td>
                      <td colspan="1">${detail.actName || ''}</td>
                      <td colspan="1">活动编码</td>
                      <td colspan="1">${detail.actCode}</td>
                      <td colspan="1">活动时间</td>
                      <td colspan="1">${detail.beginDate || ''} - ${detail.endDate || ''}</td>
                      <td colspan="1">创建人</td>
                      <td colspan="1">${detail.createName || ''}</td>
                      <td colspan="1">创建组织</td>
                      <td colspan="1">${detail.createOrgName || ''}</td>
                      <td colspan="1">创建时间</td>
                      <td colspan="1">${detail.createDateAll || ''}</td>
                    </tr>
                    <tr>
                      <td colspan="1">活动目标</td>
                      <td colspan="11">${detail.actDesc}</td>
                    </tr>
                    <tr>
                      <td colspan="12" style="height:25px"></td>
                    </tr>
                    <tr>
                      <td colspan="12">活动明细信息</td>
                    </tr>
                    ${detailHtml}
                    ${logHtml}
            </table>
             ${newHtml}`;
    this.beginPrint(html);
  }

  // 客户调账
  async printMarketAdjustBill(newHtml) {
    const detail = await this.getDetail();
    const logData = await this.getLog();
    const RMB = this.digitUppercase(detail.adjustAccountQuota);
    const adjustAccountQuota = this.moneyFormats(detail.adjustAccountQuota)
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr>
        <td colspan="1">${item.positionName}</td>
        <td colspan="1">${item.userName}</td>
        <td colspan="8">${item.content || ''}</td>
        <td colspan="2">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</td>
        </tr>`;
    });
    const html = `
      <h2 style="text-align: center;margin: 10px 0">${detail.outCompanyName}客户调账单</h2>
    <div style="display:flex;justify-content: space-between;margin-bottom: 10px">
        <p><span>单据编号:</span><span>${detail.billCode}</span></p>
        <p><span>申请日期:</span><span>${detail.createDate}</span></p>
    </div>
    <table border="1" cellspacing="0">
        <tr style="line-height: 50px">
            <td colspan="1">分公司</td><td colspan="2">${detail.responseOrgName}</td>
            <td colspan="1">区域</td><td colspan="2">${detail.responseOrgName}</td>
            <td colspan="1">申请人</td><td colspan="2">${detail.responsibleName}</td>
            <td colspan="1">调账类型</td><td colspan="2">${detail.adjustAccountTypeName}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">调出公司主体</td>
            <td colspan="5">${detail.outCompanyName}</td>
            <td colspan="1">调入公司主体</td>
            <td colspan="5">${detail.enterCompanyName}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">调出客户</td>
            <td colspan="5">${detail.outCustomerName}</td>
            <td colspan="1">调入客户</td>
            <td colspan="5">${detail.enterCustomerName}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">调出业务员</td>
            <td colspan="5">${detail.responsibleName}</td>
            <td colspan="1">调入业务员</td>
            <td colspan="5">${detail.enterBusinessmanCode}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">调账金额（大写）</td>
            <td colspan="8" style="text-align: left">${RMB}</td>
            <td colspan="1">调账金额</td>
            <td colspan="2">${adjustAccountQuota}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">备注</td>
            <td colspan="11">${detail.remark || ''}</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="12" style="text-align: center">审批意见</td>
        </tr>
        <tr style="line-height: 50px">
            <td colspan="1">审批环节</td>
            <td colspan="1">审批人</td>
            <td colspan="8">审批意见</td>
            <td colspan="2">审批时间</td>
        </tr>
        ${logTr}
    </table>
     ${newHtml}`;
    this.beginPrint(html);
  }
  //客户信息
  async customerMsg(newHtml){
    const detail = await this.getDetail();
    const logHtml = await this.commonLog(detail);
    const jointVentures = await this.getDicValue('yes_or_no');
    const jointVenturesName = this.backName(jointVentures, detail.jointVentures);
    let channel = await this.getDicValue('channel');
    let brandReqs=detail.brandResps
    let brand=brandReqs.filter((v)=>v.sales)||[]
    let noBrand=brandReqs.filter((v)=>!v.sales)||[]
    let brandData=''
    let brandList=''
    let other=[]
    let otherName=''
    if (brandReqs&&brandReqs.length>0) {
      brand.forEach((v)=>{
        brandList += `
          <div style="width:100%;">品牌：${v.brandName || ''}，销售额${v.sales || 0}万元/年</div>
        `
      })
      noBrand.forEach((v)=>{
        other.push(v.brandName)
      })
      otherName=other.join("、")
      brandData = `
        <tr>
          <td colspan="2">与其他品牌合作情况</td>
          <td colspan="6">
            ${brandList}
            <div style="width:100%;">其他：${otherName || '无'}</div>
          </td>
          <td colspan="4">总营业额${detail.brandSales || 0}万元/年</td>
        </tr>
      `
    }
    let productReqs=detail.productResps
    let productData=''
    if (productReqs&&productReqs.length>0) {
      productReqs.forEach((v)=>{
        v.saleChannelName = this.backName(channel, v.saleChannel);
        productData += `
          <tr>
            <td colspan="2">计划合作产品</td>
            <td colspan="2">${v.productName || ''}</td>
            <td colspan="2">销售区域</td>
            <td colspan="2">${v.saleArea || ''}</td>
            <td colspan="2">销售渠道</td>
            <td colspan="2">${v.saleChannelName || ''}</td>
          </tr>
          <tr>
            <td colspan="2">年度销售任务（万元）</td>
            <td colspan="2">${v.salesTask || 0}</td>
            <td colspan="2">首单回款（万元）</td>
            <td colspan="2">${v.firstReturn || 0}</td>
            <td colspan="2">专项运作团队</td>
            <td colspan="2">${v.employeeNum || 0}人</td>
          </tr>
        `
      })
    }
    let establishedTime=((detail.establishedTime||'').split("-")||[])[0]||''
    const html = `
    <h2 style="text-align: center;margin: 10px 0">西凤酒新开经销商立户审批评估报告</h2>
    <table style="width: 100%;" border="1" cellspacing="0">
        <tr>
          <td colspan="12">一、经销商概况</td>
        </tr>
        <tr>
          <td colspan="2">经销商名称</td>
          <td colspan="2">${detail.customerName || ''}</td>
          <td colspan="2">地址/电话</td>
          <td colspan="6">${detail.registeredAddress || ''}</td>
        </tr>
        <tr>
          <td colspan="2">经营区域</td>
          <td colspan="2">${detail.saleArea || ''}</td>
          <td colspan="2">成立时间</td>
          <td colspan="2">${establishedTime ||detail.establishedTime|| ''}</td>
          <td colspan="2">注册资金（万元）</td>
          <td colspan="2">${detail.registerAmount || 0}</td>
        </tr>
        <tr>
          <td colspan="2">流动资金（万元）</td>
          <td colspan="2">${detail.workingAmount || 0}</td>
          <td colspan="2">是否为合资公司</td>
          <td colspan="2">${jointVenturesName|| ''}</td>
          <td colspan="2">实际操盘人（姓名电话）</td>
          <td colspan="2">${detail.possessor || ''}${detail.possessorMobile || ''}</td>
        </tr>
        <tr>
          <td colspan="2">终端网点数量</td>
          <td colspan="2">${detail.onlineNum || 0}余家</td>
          <td colspan="2">团购单位（家）</td>
          <td colspan="2">${detail.groupNum || 0}</td>
          <td colspan="2">配送车辆（辆）</td>
          <td colspan="2">${detail.vehiclesNum || 0}余台</td>
        </tr>
        <tr>
          <td colspan="2" rowspan="4">业务范围</td>
        </tr>
        <tr>
          <td colspan="10">主营、兼营：${detail.businessContent || ''}</td>
        </tr>
        <tr>
          <td colspan="10">地域、覆盖范围：${detail.saleArea || ''}</td>
        </tr>
        <tr>
          <td colspan="10">经营情况：${detail.businessCondition || ''}</td>
        </tr>
        <tr>
          <td colspan="6">员工总人数${detail.employeeNum || 0}人。</td>
          <td colspan="6">其中管理人员${detail.administratorNum || 0}人，业务人员${detail.salesmanNum || 0}人。</td>
        </tr>
        <tr>
          <td colspan="6">自营店铺${detail.storeNum || 0}间，共${detail.storeSquare || 0}平方米。</td>
          <td colspan="6">自有仓库${detail.warehouseNum || 0}个，共${detail.warehouseSquare || 0}平方米。</td>
        </tr>
        ${brandData}
        <tr>
          <td colspan="12">二、西风酒合作概况</td>
        </tr>
        ${productData}
        <tr>
          <td colspan="12">三、业务审批流程</td>
        </tr>
        ${logHtml}
    </table>
     ${newHtml}`;
    this.beginPrint(html);
  }
  // 退换货申请
  async printMarketReturnProduct(newHtml) {
    console.log(this.id);
    if (!this.id) return;
    const { result } = await request.post(this.url, [this.id]);
    const detail = result;
    let logData = await this.getLog();
    logData = logData.reverse()
    let logDataList = logData.filter((v)=>v.taskName !== '评审组会签'&&v.taskName !== '评审组组长')
    let logDataLength = logData.filter((v)=>v.taskName === '评审组会签')
    let logDataArr = logData.filter((v)=>v.taskName === '评审组组长')
    let logTr = '';
    let marketing = '';
    let reviewGroupData = '';
    let reviewGroup = '';
    let reviewGroupLeader = '';
    logDataList.forEach((item, index) => {
      logTr += `<tr rowspan="1">
        <td colspan="3">${item.taskName||''}</td>
        <td colspan="9">
          <p style="margin-bottom: 10px">${item.content || ''}</p>
          <div style="display:flex;flex-direction: column;align-items: flex-end;text-align: left">
            <p style="width: 120px">${item.userName}</p><p style="width: 120px">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</p>
          </div>
        </td>
      </tr>`;   
    });
    logDataLength.forEach((vitem, index)=>{
      reviewGroup += `<td colspan="9" rowspan="1">
            <p style="margin-bottom: 10px">${vitem.content || ''}</p>
            <div style="display:flex;flex-direction: column;align-items: flex-end;text-align: left">
                <p style="width: 120px">${vitem.userName}</p><p style="width: 120px">${vitem.logDate || ''}&nbsp;&nbsp;${vitem.createTime || ''}</p>
            </div>
          </td>
        </tr>
        <tr>`;
    })
    if (logDataLength.length>0) {
      reviewGroupData = `<tbody>
        <tr>
          <td colspan="3" rowspan="${logDataLength.length}">评审组会签</td>
          ${reviewGroup}
        </tr>
      </tbody>` 
    }
    if (logDataArr.length>0) {
      logDataArr.forEach((item, index) => {
        reviewGroupLeader += `<tr rowspan="1">
          <td colspan="3">${item.taskName||''}</td>
          <td colspan="9">
            <p style="margin-bottom: 10px">${item.content || ''}</p>
            <div style="display:flex;flex-direction: column;align-items: flex-end;text-align: left">
                <p style="width: 120px">${item.userName}</p><p style="width: 120px">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</p>
            </div>
          </td>
        </tr>`;  
      });
    }
    let title = this.processKey==='returnexchange_goods'?'质量问题退换货审批表':this.processKey==='returnexchange_goods-01'?'营销市场问题退换货审批表':'成品酒退（换）货审批表'
    const html = `
        <h2 style="text-align: center;margin: 10px 0">${title}</h2>
        <p style="margin-bottom: 10px;"><span>单据编号:</span><span>${detail[0].billCode}</span></p>
        <table border="1" cellspacing="0">
        <tr style="line-height: 50px">
            <td colspan="3">填写人</td>
            <td colspan="9">填写事项</td>
        </tr>
        <tbody>
        <tr style="line-height: 50px" rowspan="8">
            <td colspan="3" rowspan="8">客户</td>
            <td colspan="4" rowspan="1">客户名：${detail[0].customerName}</td>
            <td colspan="3" rowspan="1">联系方式：${detail[0].linkPhone}</td>
            <td colspan="2" rowspan="1">日期: ${detail[0].createDate}</td>
        </tr>
        <tr>
            <td colspan="4" rowspan="1">产品名称</td>
            <td colspan="5" rowspan="1">产品编码</td>
        </tr>
        <tr>
            <td colspan="4" rowspan="1">${detail[0].productName}</td>
            <td colspan="5" rowspan="1">${detail[0].productCode}</td>
        </tr>
        <tr>
            <td colspan="4" rowspan="1">规格</td>
            <td colspan="3" rowspan="1">单位</td>
            <td colspan="2" rowspan="1">数量</td>
        </tr>
        <tr>
          <td colspan="4" rowspan="1">${detail[0].productSpec || ''}</td>
            <td colspan="3" rowspan="1">${detail[0].office}</td>
            <td colspan="2" rowspan="1">${detail[0].productNumber}</td>
        </tr>
        <tr>
            <td colspan="9" rowspan="1">
                <p>批次:</p>
                <p>${detail[0].productBarch || ''}</p>
            </td>
        </tr>
        <tr>
            <td colspan="9" rowspan="2">
                <p>退（换）货原因:</p>
                <p>${detail[0].returnCause}</p>
            </td>
        </tr>

        </tbody>
        <tr>
            <td colspan="3">经办人</td>
            <td colspan="9">${detail[0].createName}</td>
        </tr>
        ${logTr}
        ${reviewGroupData}
        ${reviewGroupLeader}
        <tbody>
            <tr style="height: 80px">
                <td colspan="3" >备注</td>
                <td colspan="9"></td>
            </tr>
        </tbody>
    </table>
     ${newHtml}`;
    this.beginPrint(html);
  }

  // 新产品研发申请
  async developmentProduct(newHtml) {
    const detail = await this.getDetail('post');
    let smellType = {};
    let wineLevel = {};
    let alcoholDegree = {};
    const params = ['product_flavortype', 'winebody_grade', 'alcohol_degree'];
    request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
      if (res.success) {
        const productFlavortype = res.result.product_flavortype || [];
        smellType = productFlavortype.find((a) => a.dictCode === detail.smellType);
        const winebodyGrade = res.result.winebody_grade || [];
        wineLevel = winebodyGrade.find((a) => a.dictCode === detail.wineLevel);
        const alcoholDegreeList = res.result.alcohol_degree || [];
        alcoholDegree = alcoholDegreeList.find((a) => a.dictCode === detail.alcoholDegree);
      }
    });
    let logData = await this.getLog();
    logData = logData.reverse();
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr>
        <td colspan="4">${item.positionName}</td>
        <td colspan="20">
            <p style="margin-bottom: 10px">${item.content || ''}</p>
              <div style="display:flex;align-items: flex-end;text-align: left">
                  <p style="width: 150px;"><span>审批人</span><span>${item.userName}</span></p>
                  <p style="width: 150px;"><span>日期</span>${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</p>
              </div>
        </td>
        </tr>`;
    });
    const html = `
        <h2 style="text-align: center;margin: 10px 0">西凤酒新产品开发申请表</h2>
        <div style="display:flex;justify-content: space-between;margin-bottom: 10px">
            <p>${detail.billCode}</p>
            <p><span>申请日期:</span><span>${detail.createDate}</span></p>
        </div>
        <table border="1" cellspacing="0">
            <tr style="line-height: 50px">
              <td colspan="4">申请单位</td>
              <td colspan="8">${detail.companyName}</td>
              <td colspan="4">联系人</td>
              <td colspan="8">${detail.customerName}</td>
            </tr>
            <tr>
                <td colspan="4">联系地址</td>
                <td colspan="8">${detail.registeredAddress}</td>
                <td colspan="4">联系电话</td>
                <td colspan="8">${detail.customerContact}</td>
            </tr>
            <tr>
                <td colspan="4">申请单位基本情况（公司规模、营销网络、售后服务等）</td>
                <td colspan="20">${detail.basicInfo}</td>
            </tr>
            <tr>
                <td colspan="24">产品主要情况</td>
            </tr>
            <tr>
                <td colspan="4">产品名称</td>
                <td colspan="12">${detail.productName||''}</td>
                <td colspan="4">产品编号</td>
                <td colspan="4">${detail.productCode || ''}</td>
            </tr>
            <tr>
                <td colspan="4">产品类型</td>
                <td colspan="4">${detail.productType}</td>
                <td colspan="4">香型</td>
                <td colspan="4">${(smellType||{}).dictValue || ''}</td>
                <td colspan="4">酒体等级</td>
                <td colspan="4">${(wineLevel||{}).dictValue || ''}</td>
            </tr>
            <tr>
                <td colspan="4">酒精度</td>
                <td colspan="4">${(alcoholDegree||{}).dictValue||''}</td>
                <td colspan="4">净含量</td>
                <td colspan="4">${detail.content || ''}</td>
                <td colspan="4">规格</td>
                <td colspan="4">${detail.standard || ''}</td>
            </tr>
            <tr>
                <td colspan="4">销售区域</td>
                <td colspan="12">${detail.saleRegion||''}</td>
                <td colspan="4">产品定位</td>
                <td colspan="4">${detail.productLocal || ''}</td>
            </tr>
            <tr>
                <td colspan="4">销售区域</td>
                <td colspan="12">${detail.saleRegion}</td>
                <td colspan="4">产品定位</td>
                <td colspan="4">${detail.productLocal || ''}</td>
            </tr>
            <tr>
                <td colspan="4">三年预期销售量（万元）</td>
                <td colspan="3">第一年</td>
                <td colspan="3">${detail.firstYear}</td>
                <td colspan="3">第二年</td>
                <td colspan="3">${detail.secondYear}</td>
                <td colspan="4">第三年</td>
                <td colspan="4">${detail.thirdYear}</td>
            </tr>
            <tr>
                <td colspan="4">包装设计要求</td>
                <td colspan="20"> ${detail.packRequire}</td>
            </tr>
            <tr>
                <td colspan="24">市场分析</td>
            </tr>
            <tr>
                <td colspan="4">需求分析</td>
                <td colspan="20"> ${detail.requireAnalyze}</td>
            </tr>
            <tr>
                <td colspan="4">目标市场分析</td>
                <td colspan="20"> ${detail.targentMarket}</td>
            </tr>
            <tr>
                <td colspan="4">主要竞品分析</td>
                <td colspan="20"> ${detail.mainProduct}</td>
            </tr>
            <tr>
                <td colspan="4">营销策略适应性分析</td>
                <td colspan="20"> ${detail.ideaAdapt}</td>
            </tr>
            <tr>
                <td colspan="4">产品成本及市场销售成本分析</td>
                <td colspan="20"> ${detail.productMarket}</td>
            </tr>
            <tr>
                <td colspan="4">产品成长性分析</td>
                <td colspan="20"> ${detail.productGrow}</td>
            </tr>
            <tr>
                <td colspan="4">备注</td>
                <td colspan="20"> ${detail.remark}</td>
            </tr>
            <tr>
                <td colspan="24">审批信息</td>
            </tr>${logTr}
        </table>
         ${newHtml}`;
    this.beginPrint(html);
    /**
     * <tr style="height:60px;">
          <td colspan="4">分管副总</td>
          <td colspan="20">
          </td>
        </tr>
        <tr style="height:60px;">
          <td colspan="4">董事长</td>
          <td colspan="20">
          </td>
        </tr>
     */
  }

  // 产品微调
  async adjustProduct(newHtml) {
    const { result } = await request.post(this.url, { id: this.id });
    const detail = result;
    let title2 = {};
    let title1 = {};
    let title3 = {};
    const params = ['modify_product', 'modify_product_classification', 'alcohol_degree'];
    request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
      if (res.success) {
        const modifyProduct = res.result.modify_product || [];
        title2 = modifyProduct.find((a) => a.dictCode === detail.changeType);
        const winebodyGrade = res.result.modify_product_classification || [];
        title1 = winebodyGrade.find((a) => a.dictCode === detail.changeAfterWineLevel);
        const alcoholDegree = res.result.alcohol_degree || [];
        title3 = alcoholDegree.find((a) => a.dictCode === detail.changeAfterAlcoholDegree);
      }
    });
    // const detail = await this.getDetailPost();
    let logData = await this.getLog();
    logData = logData.reverse();
    let logTr = '';
    logData.forEach((item) => { // <p style="width: 150px;"><span>日期</span>${_.toDateString(item.createTime, 'yyyy-MM-dd') || ''}</p>
      logTr += `<tr>
        <td colspan="4">${item.positionName}</td>
        <td colspan="22">
            <p style="margin-bottom: 10px">${item.content || ''}</p>
              <div style="display:flex;align-items: flex-end;text-align: left">
                  <p style="width: 450px;"><span>审批人</span><span>${item.userName}</span></p>
                  <p style="width: 150px;"><span>日期</span>${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</p>
               </div>
        </td>
        </tr>`;
    });
    const html = `
        <h2 style="text-align: center;margin: 10px 0">${detail.changeBeforeProductName}微调申报表</h2>
        <div style="display:flex;justify-content: space-between;margin-bottom: 10px">
            <p>${detail.billCode}</p>
            <p><span>申请日期:</span><span>${detail.createTime}</span></p>
        </div>
         <table border="1" cellspacing="0">
        <tr style="line-height: 50px">
            <td colspan="4">申请单位</td>
            <td colspan="9">${detail.customerName}</td>
            <td colspan="4">联系人</td>
            <td colspan="9">${detail.responsibleName}</td>
        </tr>
        <tr>
            <td colspan="4">联系地址</td>
            <td colspan="9">${detail.address}</td>
            <td colspan="4">联系电话</td>
            <td colspan="9">${detail.phoneCode}</td>
        </tr>
        <tr>
            <td colspan="4">申请单位基本情况（公司规模、营销网络、售后服务等）</td>
            <td colspan="22">${detail.applicantInfo}</td>
        </tr>
        <tr>
            <td colspan="26">产品主要情况</td>
        </tr>
        <tr>
            <td colspan="11">微调类型</td>
            <td colspan="15">${title2.dictValue || ''}</td>
        </tr>
        <tbody>
        <tr>
            <td colspan="6" rowspan="4">产品微调前情况</td>
            <td colspan="5">产品名称</td>
            <td colspan="15">${detail.changeBeforeProductName}</td>
        </tr>
        <tr>
            <td colspan="5">微调前香型</td>
            <td colspan="3">${detail.changeBeforeSmell}</td>
            <td colspan="5">酒精度</td>
            <td colspan="1">${detail.changeBeforAlcoholDegree}</td>
            <td colspan="3">上市时间</td>
            <td colspan="3">${detail.listedDate}</td>
        </tr>
        <tr>
            <td colspan="5">净含量（mL）</td>
            <td colspan="3">${detail.changeBeforeContent}</td>
            <td colspan="5">规格</td>
            <td colspan="7">${detail.changeBeforeStandard}</td>
        </tr>
        <tr>
            <td colspan="5">产品累计销售量（箱）</td>
            <td colspan="3">${detail.productCumulateSales}</td>
            <td colspan="5">现有产品库存量（箱）</td>
            <td colspan="7">${detail.nowProductStock}</td>
        </tr>
        </tbody>
        <tbody>
        <tr>
            <td colspan="6" rowspan="3">拟定产品微调后情况</td>
            <td colspan="5">产品名称</td>
            <td colspan="15">${detail.changeAfterProductName}</td>
        </tr>
        <tr>
            <td colspan="5">微调后酒体等级</td>
            <td colspan="3">${title1.dictValue || ''}</td>
            <td colspan="5">酒精度</td>
            <td colspan="1">${title3.dictValue || ''}</td>
            <td colspan="3">净含量（mL）</td>
            <td colspan="3">${detail.changeAfterContent}</td>
        </tr>
        <tr>
            <td colspan="5">规格</td>
            <td colspan="3">${detail.changeAfterStandard}</td>
            <td colspan="5">销售区域</td>
            <td colspan="7">${detail.changeAfterDistributionRegion}</td>
        </tr>
        </tbody>
        <tr>
            <td colspan="6">三年预期销售量（万元）</td>
            <td colspan="5">第一年</td>
            <td colspan="3">${detail.firstYear}</td>
            <td colspan="5">第二年</td>
            <td colspan="1">${detail.secondYear}</td>
            <td colspan="3">第三年</td>
            <td colspan="3">${detail.thirdYear}</td>
        </tr>
        <tr>
            <td colspan="11">包装材质微调差异</td>
            <td colspan="15">${detail.packChangeDifferent}</td>
        </tr>
        <tr>
            <td colspan="11">说明1</td>
            <td colspan="15">${detail.explainOne || ''}</td>
        </tr>
        <tr>
            <td colspan="11">说明2</td>
            <td colspan="15">全面调整外包装及基酒</td>
        </tr>
        <tr>
            <td colspan="26">微调原因</td>
        </tr>
        <tr>
            <td colspan="4">需求分析</td>
            <td colspan="22">${detail.requireAnalyze}</td>
        </tr>
        <tr>
            <td colspan="4">目标市场分析</td>
            <td colspan="22">${detail.targentMarket}</td>
        </tr>
        <tr>
            <td colspan="4">主要竞品分析</td>
            <td colspan="22">${detail.mainProduct}</td>
        </tr>
        <tr>
            <td colspan="4">营销策略适应性分析</td>
            <td colspan="22">${detail.ideaAdapt}</td>
        </tr>
        <tr>
            <td colspan="4">产品成本及市场销售成本分析</td>
            <td colspan="22">${detail.productMarket}</td>
        </tr>
        <tr>
            <td colspan="4">产品成长性分析</td>
            <td colspan="22">${detail.productGrow}</td>
        </tr>
        <tr>
            <td colspan="4">备注</td>
            <td colspan="22"></td>
        </tr>
        <tr><td colspan="26">审批信息</td></tr>
        ${logTr}
        </table>
         ${newHtml}`;
    this.beginPrint(html);
  }

  // 客户终止
  async customerTerminate(newHtml) {
    const detail = await this.getDetailGet();
    const logData = await this.getLog();
    let customerBusinessType = {};
    let backAccountType = {};
    const params = ['customer_business_type', 'back_account_type'];
    await request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
      if (res.success) {
        const customerType = res.result.customer_business_type || [];
        customerBusinessType = customerType.find((a) => a.dictCode === detail.businessType);
        const backAccount = res.result.back_account_type || [];
        backAccountType = backAccount.find((a) => a.dictCode === detail.backAccountType);
      }
    });
    let backBankName=''
    await request.post('/mdm/mdmbankbranch/queryBankBranchName', {bankBranchNo:detail.backBankNo}).then((res) => {
      if (res.success) {
        backBankName=res.result.find((v)=>v.bankBranchNo===detail.backBankNo).bankBranchName
      }
    });
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr>
        <td colspan="4">${item.positionName || ''}</td>
        <td colspan="8">${item.userName || ''}</td>
        <td colspan="12">${item.content || ''}</td>
        <td colspan="6">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</td>
        </tr>`;
    });
    const html = `
        <h2 style="text-align: center;margin: 10px 0">客户终止业务申请及退款</h2>
        <div style="display:flex;justify-content: space-between;margin-bottom: 10px">
            <p><span>单据编号:</span>${detail.applyCode}</p>
            <p><span>申请日期:</span><span>${detail.createDate}</span></p>
        </div>
         <table border="1" cellspacing="0">
        <tr style="line-height: 50px">
            <td colspan="4">分公司</td>
            <td colspan="3"></td>
            <td colspan="3">区域</td>
            <td colspan="2">${detail.companyAddress}</td>
            <td colspan="3">申请人</td>
            <td colspan="3">${detail.principal}</td>
            <td colspan="3">业务类型</td>
            <td colspan="3">${(customerBusinessType||{}).dictValue||detail.businessType}</td>
            <td colspan="3">凭证张数</td>
            <td colspan="3"></td>
        </tr>
        <tr>
            <td colspan="4">公司主体</td>
            <td colspan="11">${detail.companyName}</td>
            <td colspan="3">客户名称</td>
            <td colspan="12">${detail.customerName}</td>
        </tr>
        <tr>
            <td colspan="4">退款金额</td>
            <td colspan="11">${detail.backAmount}</td>
            <td colspan="3">往来户类型</td>
            <td colspan="12">${(backAccountType||{}).dictValue||detail.backAccountType||''}</td>
        </tr>
        <tr>
            <td colspan="4">往来户名称</td>
            <td colspan="11">${detail.backAccountName||''}</td>
            <td colspan="3">开户行</td>
            <td colspan="12">${backBankName||''}</td>
        </tr>
        <tr>
            <td colspan="4">开户行账号</td>
            <td colspan="11">${detail.backBankNum||''}</td>
            <td colspan="15"></td>
        </tr>
        <tr>
            <td colspan="4">备注</td>
            <td colspan="26"></td>
        </tr>
        <tr><td colspan="30">审批信息</td></tr>
         <tr>
            <td colspan="4">审批环节</td>
            <td colspan="8">审批人</td>
            <td colspan="12">审批意见</td>
            <td colspan="6">审批时间</td>
        </tr>
        ${logTr}
        </table>
         ${newHtml}`;
    this.beginPrint(html);
  }

  // 经销商费用上账
  async costSummary(newHtml) {
    const detail = await this.getDetailGet();
    const logData = await this.getLog();
    let logTr = '';
    logData.forEach((item) => {
      logTr += `<tr style="height: 50px">
        <td colspan="8">${item.userName || ''}</td>
        <td colspan="16">${item.content || ''}</td>
        <td colspan="6">${item.logDate || ''}&nbsp;&nbsp;${item.createTime || ''}</td>
        </tr>`;
    });
    const html = `
      <h2 style="text-align: center;margin: 10px 0">经销商费用上账申请打印表</h2>
     <table border="1" cellspacing="0">
        <tr style="height: 50px">
            <td colspan="5">申请编号</td>
            <td colspan="10">${detail.applyCode || ''}</td>
            <td colspan="5">经销商</td>
            <td colspan="10">${detail.dealerName || ''}</td>
        </tr>
        <tr style="height: 50px">
            <td colspan="5">申请金额</td>
            <td colspan="25">${detail.applyAmount || ''}</td>
        </tr>
        <tr style="height: 50px">
            <td colspan="5">公司主体</td>
            <td colspan="10">${detail.companyName || ''}</td>
            <td colspan="5">产品系列</td>
            <td colspan="10">${detail.productSeriesName || ''}</td>
        </tr>
        <tr style="height: 50px"><td colspan="30">审批信息</td></tr>
        <tr style="height: 50px">
          <td colspan="8">经办人</td>
          <td colspan="16">审批意见</td>
          <td colspan="6">审批时间</td>
        </tr>
        ${logTr}
      </table>
      ${newHtml}
      `;
    this.beginPrint(html);
  }
}
